<template>
	<CRow>
		<CCol sm="12">
			<TopHeadline :headerTitle="$t('pages.account.editUser')" :backTitle="$t('common.back')" :onGoBack="onBackPage">
				<template v-slot:headerRight v-if="editingAccount != null">
					<CButton type="button" color="primary" @click="onUpdateAccount">{{ $t('common.update') }}</CButton>
										
				</template>
			</TopHeadline>

			<Accordion :headerTitle="$t('pages.account.accountInfomation')" v-if="editingAccount != null">
				<template v-slot:body>
					<CRow>
						<CCol sm="12">
							<CForm>
								<CInput :label="$t('common.userName')" :value="editingAccount.userName" readonly horizontal>
								</CInput>
								<CInput :placeholder="$t('pages.account.fullName')" v-model="editingAccount.fullName"
										horizontal>
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.fullName') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>
								<CInput :label="$t('common.email')" :value="editingAccount.email" readonly horizontal></CInput>
								<CInput :label="$t('common.phone')" :placeholder="$t('common.enterPhoneNumber')" v-model="editingAccount.phoneNumber" @keypress="onlyNumbers" horizontal></CInput>
								<CInput :label="$t('common.address')" :placeholder="$t('pages.account.enterAddress')" v-model="editingAccount.address" horizontal> </CInput>
								<CInput label="Loại tài khoản" :value="editingAccount.role" readonly horizontal></CInput>
							</CForm>
						</CCol>

					</CRow>
				</template>
			</Accordion>

			<Confirmation ref="confirmation"></Confirmation>
		</CCol>
	</CRow>
</template>

<script>
	import { mapGetters, mapState, mapActions } from 'vuex'
    import Accordion from '@/components/Accordion.vue'
	import TopHeadline from '@/components/TopHeadline.vue'
    import Confirmation from '@/components/Confirmation.vue'

	export default {
		name: 'Tables',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
			}
		},
        components: {
            Confirmation,
            Accordion,
            TopHeadline
        },
        computed: {
			...mapState('role', ['roles']),
			...mapGetters('account', ['editingAccount']),
            ...mapGetters('auth', ['user']),
        },  
		methods: {           
			...mapActions('role', ['getAllRoles']),
            async onUpdateAccount() {
				
				var retVal = await this.$store.dispatch("account/saveAccount");
				if (retVal == true) {
                    this.$router.push('/users/list/');
                }
			},
            onBackPage() {
				
				this.$router.push('/users/list/');
            },
			onlyNumbers(event) {      
				let keyCode = event.keyCode ? event.keyCode : event.which;
				if (keyCode < 48 || keyCode > 57) {
					// 46 is dot
					event.preventDefault();
				}
			},
		},
		mounted() {
			if(this.roles == null || this.roles.length == 0) {
				this.getAllRoles();
			}
            this.$store.dispatch("account/editAccount", this.$route.params.id);           
		}
	}
</script>